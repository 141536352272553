@import './libs/bootstrap-inline.css';

/*  Todo
*   Add necessary imports from style.css
*/

@import './style/variables.css';
@import './style/base.css';
@import './style/base-layout.css';
@import './style/base-typo.css';

/*@import './style/row--gutter.css';*/
/*@import './style/vertical-gutter.css';*/
/*@import './style/row--same-height.css';*/
/*@import './style/list-helpers.css';*/


@import './style/btn.css';
@import './style/icons.css';
@import './style/console.css';
@import './style/navbar.css';
@import './style/navbar-mobile.css';
/*@import './style/navbar-dropdown.css';*/
@import './style/navbar-overlay.css';
@import './style/sidebar.css';
/*@import './style/modal.css';*/
@import './style/oocss.css';

/*@import './style/footer.css';*/
@import './style/page-heading.css';
/*@import './style/icon-cta.css';*/
/*@import './style/accordion.css';*/
/*@import './style/simple-accordion.css';*/
/*@import './style/text-with-media.css';*/
/*@import './style/contact.css';*/
/*@import './style/blockquote.css';*/
/*@import './style/custom-radio.css';*/
/*@import './style/form-errors.css';*/
/*@import './style/content-block.css';*/
@import './style/slide.css';
/*@import './style/wysiwyg.css';*/
/*@import './style/shariff.css';*/
@import './style/hero-slide.css';
/*@import './style/slider.css';*/
/*@import './style/triple-slider.css';*/
/*@import './style/box-teaser.css';*/
/*@import './style/img-teaser.css';*/
/*@import './style/form.css';*/
/*@import './style/info-widget.css';*/
/*@import './style/video.css';*/
@import './style/image-page-heading.css';
/*@import './style/billboard.css';*/
/*@import './style/download-teaser.css';*/
/*@import './style/flags.css';*/
/*@import './style/social-icons.css';*/
/*@import './style/custom-dropdown.css';*/
/*@import './style/history.css';*/
@import './style/page-nav.css';
@import '@elements/cookie-permissions/cookie.css';
@import './style/cookie-bar-overrides.css';

.navbar-main__dropdown,
.js-slider-item + .js-slider-item,
.js-slider > div + div,
.collapse:not(.show) {
    display: none;
}

.js-slider-item {
    margin: 0 auto;
}