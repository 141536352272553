@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1737628015881/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1737628015881/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1737628015881/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-arrow-down-filled:before { content: "\EA01" }
.icon-arrow-left:before { content: "\EA02" }
.icon-arrow-medium-down:before { content: "\EA03" }
.icon-arrow-medium-right:before { content: "\EA04" }
.icon-check:before { content: "\EA05" }
.icon-clock:before { content: "\EA06" }
.icon-close:before { content: "\EA07" }
.icon-contact:before { content: "\EA08" }
.icon-doc:before { content: "\EA09" }
.icon-elements:before { content: "\EA0A" }
.icon-eps:before { content: "\EA0B" }
.icon-facebook:before { content: "\EA0C" }
.icon-instagram:before { content: "\EA0D" }
.icon-iso:before { content: "\EA0E" }
.icon-jpg:before { content: "\EA0F" }
.icon-link:before { content: "\EA10" }
.icon-linkedin:before { content: "\EA11" }
.icon-mail:before { content: "\EA12" }
.icon-pdf:before { content: "\EA13" }
.icon-phone:before { content: "\EA14" }
.icon-play:before { content: "\EA15" }
.icon-plus:before { content: "\EA16" }
.icon-scroll:before { content: "\EA17" }
.icon-search:before { content: "\EA18" }
.icon-shipping:before { content: "\EA19" }
.icon-tiktok:before { content: "\EA1A" }
.icon-tracking:before { content: "\EA1B" }
.icon-world:before { content: "\EA1C" }
.icon-xing:before { content: "\EA1D" }
.icon-youtube:before { content: "\EA1E" }


:root {
--icon-arrow-down-filled: "\EA01";
    --icon-arrow-left: "\EA02";
    --icon-arrow-medium-down: "\EA03";
    --icon-arrow-medium-right: "\EA04";
    --icon-check: "\EA05";
    --icon-clock: "\EA06";
    --icon-close: "\EA07";
    --icon-contact: "\EA08";
    --icon-doc: "\EA09";
    --icon-elements: "\EA0A";
    --icon-eps: "\EA0B";
    --icon-facebook: "\EA0C";
    --icon-instagram: "\EA0D";
    --icon-iso: "\EA0E";
    --icon-jpg: "\EA0F";
    --icon-link: "\EA10";
    --icon-linkedin: "\EA11";
    --icon-mail: "\EA12";
    --icon-pdf: "\EA13";
    --icon-phone: "\EA14";
    --icon-play: "\EA15";
    --icon-plus: "\EA16";
    --icon-scroll: "\EA17";
    --icon-search: "\EA18";
    --icon-shipping: "\EA19";
    --icon-tiktok: "\EA1A";
    --icon-tracking: "\EA1B";
    --icon-world: "\EA1C";
    --icon-xing: "\EA1D";
    --icon-youtube: "\EA1E";
    
}